@use '@angular/material' as mat;

@include mat.core();

$theme-primary: mat.define-palette(mat.$green-palette);
$theme-accent: mat.define-palette(mat.$red-palette);
$theme-warn: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

@include mat.all-component-themes($theme);

// https://github.com/CuppaLabs/angular2-multiselect-dropdown/blob/master/src/themes/default.theme.scss
.c-btn {
  background: #ffffff;

  border: 1px solid #bbb;
  border-radius: 8px;
}

.selected-list {
  .c-list {
    .c-token {
      background: #313131;
      color: #ffffff;

      .c-label {

      }

      .c-remove {
        svg {

        }
      }
    }
  }
}

.dropdown-list {
  .list-area {
    .filter-select-all {
      label.nodata-label {
        display: none;
      }
    }

    .btn-container {
      text-align: right;
      padding: 5px;

      .c-btn {
        border-radius: 8px;
        background-color: #8CC63F;
        font-size: 140%;
        color: #fff;
        border: 0;

      }
    }
  }
}
