
$dark: #403f41;
$darker: #231F20;
$light: #fff;
$gray: #bbbdc0;
$offWhite: #f1f1f2;
$red: #ED1C24;
$green: #8CC63F;
$yellow: #c1bf51;
$grey: #777;

$primary: #f4c455;
$secondary: #bbbdc0;

$radius: 8px;


// Define Breakpoints
// $screen-md-min: 415px;
$screen-md-min: 700px;

$screen-lg-min: 1200px;

// iPad vertical devices
@mixin ivert {
   @media (min-width: 800px) {
       @content;
   }
}

// Medium devices
@mixin md {
   @media (min-width: #{$screen-md-min}) {
       @content;
   }
}

// iPad horizontal devices
@mixin ihor {
   @media (min-width: 1024px) {
       @content;
   }
}

// Large devices
@mixin lg {
   @media (min-width: #{$screen-lg-min}) {
       @content;
   }
}
