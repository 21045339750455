/* Global Styles */

@import "_variables.scss";

body {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: $dark;
}

html {
  box-sizing: border-box;
  font-size: 10px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.w-100 {
  width: 100%;
}

section.full {
  position: absolute;
  top: 91px;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: #ddc54c;
}

.hidden {
    display: none !important;
}

p {
    margin: 0 0 1rem 0;
}

a {
    cursor: pointer;
    color: $gray;
    font-weight: 600;
    text-decoration: none;
    transition: color 200ms;
    &:hover, &:focus, &:active {
        color: $dark;
    }
}

.detail a {
  text-decoration: underline;
  color: #496ba4;

  &:hover, &:focus, &:active {
    color: #233960;
  }
}

img {
  display: inline-block;
}

.error {
  font-weight: 700;
  color: $red;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.button {
    display: inline-block;
    border: 1px solid $dark;
    padding: 0.25rem 1rem;
    border-radius: 0.5rem;
    box-shadow: none;
    background-color: $dark;
    font-size: 1.875rem;
    color: $light;
    cursor: pointer;
    transition: background-color 200ms, border-color 200ms;
    outline: none;

    &:hover {
        background-color: $darker;
        border-color: $darker;
    }

    &.green {
        background-color: $green;
        border-color: $green;
        &:hover {
            background-color: darken($green, 5);
            border-color: darken($green, 5);
        }
    }
    &.red {
        background-color: $red;
        border-color: $red;
        &:hover {
            background-color: darken($red, 5);
            border-color: darken($red, 5);
        }
    }
    &.yellow {
        background-color: $yellow;
        border-color: $yellow;
        &:hover {
            background-color: darken($yellow, 5);
            border-color: darken($yellow, 5);
        }
    }
    &.grey {
      background-color: $grey;
      border-color: $grey;
      &:hover {
        background-color: darken($grey, 5);
        border-color: darken($grey, 5);
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
}

.button-full {
  text-align: center;
  display: block;
  width: 100%;
  margin: 16px 0;
  transition: background-color 0.3s ease;
  border: none;
  padding: 8px;

  border-radius: 0.5rem;
  box-shadow: none;
  background-color: $dark;
  font-size: 1.875rem;
  color: $light;
  cursor: pointer;
  outline: none;

  &:hover {
      background-color: $darker;
      border-color: $darker;
  }
  &.green {
      background-color: $green;
      border-color: $green;
      &:hover {
          background-color: darken($green, 5);
          border-color: darken($green, 5);
      }
  }
  &.red {
      background-color: $red;
      border-color: $red;
      &:hover {
          background-color: darken($red, 5);
          border-color: darken($red, 5);
      }
  }
  &.yellow {
      background-color: $yellow;
      border-color: $yellow;
      &:hover {
          background-color: darken($yellow, 5);
          border-color: darken($yellow, 5);
      }
  }
  &.grey {
    background-color: $grey;
    border-color: $grey;
    &:hover {
      background-color: darken($grey, 5);
      border-color: darken($grey, 5);
    }
  }

  &.inactive {
    background-color: #aaa8a8;
    border-color: #bbbaba;
    color: #dddddd;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.disabled {
    background-color: $grey;
    border-color: $grey;
    &:hover {
      background-color: $grey;
      border-color: $grey;
    }
  }
}

.line-field {
  display: flex;
  margin: 4px 0;
  font-size: 1.5rem;
  padding: 0 8px;

  &.warning {
    background-color: #c3c97a;
  }

  .label {
    flex: 70px 0 0;
    text-align: right;
  }

  .label-large {
    flex: 135px 0 0;
    text-align: right;
  }

  > div {
    line-height: 40px;
  }

  input, select {
    text-align: right;
    width: 50px;
    flex-grow: 1;
    margin: 0 8px;
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $gray;
    font-weight: 600;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $gray;
    font-weight: 600;
}
:-ms-input-placeholder { /* IE 10+ */
    color: $gray;
    font-weight: 600;
}
:-moz-placeholder { /* Firefox 18- */
    color: $gray;
    font-weight: 600;
}

.input {
    label:not(.hidden) {
        display: inline-block;
        font-size: 1.375rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    input {
        // width: 100%;
        padding: 0.75rem 1.375rem;
        border: 1px solid $gray;
        border-radius: 0.5rem;
        box-shadow: none;
        outline: none;
        font-size: 1.125rem;
        font-weight: 600;
        transition: border-color 200ms;
        &:focus {
            border-color: $dark;
        }
    }
}

.form-actions {
    display: flex;
    align-items: center;
    &.right {
        justify-content: flex-end;
        > * {
            margin-left: 2rem;
        }
    }
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.font-700 {
    font-weight: 700;
}

.mt-2 {
    margin-top: 2rem;
}
.pl-2 {
    padding-left: 2rem;
}

.label {
    font-size: 1.375rem;
    margin-bottom: 0.5rem;
}

.action {
    cursor: pointer;
}

.select-button {
  font-size: 1.25rem;
  font-weight: 700;
  border: 1px solid $secondary;
  border-radius: 0.5rem;
  padding: 0.5rem 3rem 0.5rem 0.7rem;
  position: relative;
  cursor: pointer;
  &:after {
      content: '';
      position: absolute;
      width: 1rem;
      height: 1.5rem;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('/assets/global/icon-chevron-right-gray.svg');
      background-repeat: no-repeat;
      background-position: center;
  }

  img {
    vertical-align: top;
    width: 25px;
    height: 25px;
  }

  span {
    vertical-align: top;
    display: none;
    height: 25px;
    line-height: 25px;
    margin: 0 0 0 8px;
  }

  &.open {
    &:after {
      transform: translateY(-50%) rotate(90deg);
    }
    .select-menu {
      display: block;
    }
  }
}

.select-menu {
  display: none;
  position: absolute;
  z-index: 200;
  left: 0;
  bottom: -1.5rem;
  transform: translateY(100%);
  border: 1px solid $secondary;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  background-color: $light;
  &:after {
    position: absolute;
    content: '';
    top: calc(-0.625rem - 1px);
    left: 2.5rem;
    width: 1.25rem;
    height: 1.25rem;
    background: $light;
    border-left: 1px solid $secondary;
    border-top: 1px solid $secondary;
    transform: rotate(45deg);
    z-index: -1;
  }

  a {
    display: block;
    width: 200px;
    padding: 1rem;

    span {
      display: inline-block;
    }

    &:hover {
      background-color: #f6f6f6;
    }

    &.active {
      background-color: #f1f1f2;
    }
  }

}


.dropdown-button {
  height: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.25rem;
  font-weight: 700;

  svg {
    width: 25px;
    fill: $dark;
  }

  span {
    display: none;
    margin: 0 0 0 8px;
  }

  &.open {
    color: $light;
    background-color: $dark;

    svg {
      fill: $light;
    }

    &:after {
      transform: translateY(-50%) rotate(90deg);
    }
    .dropdown-menu {
      display: block;
    }
  }
}

.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 200;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  color: $light;
  background-color: $dark;
  border: solid 1px $light;
  border-top-width: 0;
  margin-right: -1px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 0.5rem 0;
  max-height: 250px;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  a {
    display: block;
    min-width: 200px;
    padding: 1rem;
    color: $light;

    &:hover {
      background-color: $darker;
    }
  }
}


.close {
  cursor: pointer;
  float: right;
  height: 42px;
  width: 42px;
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/assets/global/icon-remove.svg');
}

.small-close-button {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
  margin-right: 4px;
  mask: url('/assets/global/icon-remove.svg');
  background-color:#000;

  &:hover {
    background-color: red;
  }
}

.copy-icon {
  cursor: pointer;
  background-image: url(/assets/global/copy-regular.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.detail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 1px solid $gray;
  background: $offWhite;

  display: flex;
  flex-direction: column;

  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.230, 1.000, 0.320, 1.000);

  &.visible {
    transform: translateX(0%);
  }

  &.shoved {
    transform: translateX(-100%);
  }

  header {
    border-bottom: 1px solid $dark;
    height: 42px;
    clear: both;

    h2 {
      text-align: center;
      margin: 0;
      line-height: 42px;

      span {
        font-size: 60%;
      }
    }
    .back {
      cursor: pointer;
      float: left;
      height: 42px;
      width: 42px;
      background-size: 75%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('/assets/global/icon-long-arrow-left.svg');
    }
  }

  section {
    width: 330px;
    padding: 2rem;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    flex-grow: 1;
  }
  
  .field {
    padding: 8px;
  
    label {
      font-weight: bold;
      font-size: 2rem;
    }
  }

  .loading {
    font-size: 200%;
    color: #BBB;
  }

  label {
    display: block;
    font-size: 1.5rem;
  }

  input {
    display: block;
    width: 100%;
    font-size: 1.7rem;
  }

  .input-set {
    margin-bottom: 16px;
    overflow: auto;

    > div {
      padding: 4px 0;
    }

    input {
      display: block;
      width: 100%;
      border: 1px solid #bbb;
      font-size: 1.8rem;
      padding: 6px;
      border-radius: 8px;
    }

    textarea {
      width: 100%;
      height: 100px;
      font-family: inherit;
      border: 1px solid #bbb;
      font-size: 1.8rem;
      padding: 6px;
      border-radius: 8px;
    }

    .button {
      float: right;
      margin-top: 16px;
    }
  }

  .input-set-name {
    input {
      font-size: 2rem;
    }
  }

  .buttons {
    .button {
      margin: 12px;
    }
  }

  // .invisible {
  //   visibility: hidden;
  //   position: absolute;
  // }
}

.invisible {
  visibility: hidden;
  position: absolute;
}








.row {
  // margin: 0 0 12px;

  > div {
    display: inline-block;
    vertical-align: top;
  }

  .label {
    width: 240px;
    text-align: right;
    font-weight: 700;
    overflow-x: hidden;

    span {
      font-weight: normal;
    }
  }

  .value {
    padding: 0 8px;
    width: 88px;
    text-align: right;
    overflow-x: hidden;
  }
}

.context {
  margin: 0 -20px;

  .label {
    width: 33%;
  }

  .value {
    width: 67%;
    text-align: left;
    margin-bottom: 0.5rem;
  }
}

.address {
  margin: 16px -20px;

  .label {
    font-weight: 700;
    text-align: right;
    width: 30%;
  }
}





.background-pop {
  background-image: url('https://redfiretechnology.com/dev/assets/css/images/signal.svg');
  background-color: rgba(0, 0, 0, 0.9);
}









.md-drppicker {
  // background-color: #f00 !important;
  max-height: 250px;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;

  .btn {
    background-color: #313131 !important;
  }

  .ranges ul li button.active {
    background-color: #313131 !important;
  }

  td.active {
    background-color: #313131 !important;
  }
}






/* DRAGULA */
/* in-flight clone */
.gu-mirror {
  display: none;
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
  img {
    display: none;
  }
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}

.stretcher {
  flex-grow: 1;
}

.full-screen-dialog {
  max-width: 1024px !important;
  max-height: 1024px !important;

  .mat-dialog-container {
    max-width: none;
    // width: 100vw;
    // height: 100vh;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.load-conversation {
  float: right;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url('/assets/global/commenting-o.svg');
  background-position: center;
  background-size: contain;
}




@include md {
  .md-drppicker {
    &.double {
      width: 750px !important;
    }

    max-height: none;
  }

  .select-button {
    span {
      display: inline-block;
    }
  }

  .dropdown-button {
    span {
      display: inline-block;
    }
  }

  .dropdown-menu {
    max-height: 350px;
  }



  .detail {
    position: inherit;
    height: 100%;
  }

}

@include lg {
  .full-screen-dialog {
    // max-width: 1024px !important;
    // max-height: 1024px !important;

    .mat-dialog-container {
      max-width: none;
      width: 100vw;
      height: 100vh;
      padding: 0;
      position: inherit;
    }
  }
}

@media print {

  .no-print {
    display: none;
  }


  body {
    position: absolute;
    overflow: visible;
    top: 0;
    right: 0;
    left: 0;
    bottom: auto;

  }
  
  html {
    box-sizing: border-box;
    font-size: 10px;
  }
  
  
  section.full {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    // background-color: #ddc54c;
  }



}
// Added by Angular Material
// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
